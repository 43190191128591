<template>
  <div class="KCmodule">
    <Header title="中间文件" index="首页" titleOne="工程专利" beforeTitle="中间文件" />
    <div class="content">
      <div class="topUsers">
        <el-select
          clearable
          v-model="patprojectParams.patent_type"
          placeholder="请选择专利类型"
          @change="changePatentType"
        >
          <el-option label="发明专利" value="1">发明专利</el-option>
          <el-option label="实用新型" value="2">实用新型</el-option>
          <el-option label="外观设计" value="3">外观设计</el-option>
          <el-option label="植物新品种" value="4">植物新品种</el-option>
          <el-option label="国家新药" value="5">国家新药</el-option>
          <el-option label="集成电路布图设计专有权" value="6">集成电路布图设计专有权</el-option>
          <el-option label="国家级农作物品种" value="7">国家级农作物品种</el-option>
          <el-option label="国家一级中药保护品种" value="8">国家一级中药保护品种</el-option>
          <el-option label="其中：国防专利" value="9">其中：国防专利</el-option>
        </el-select>

        <el-input
          clearable
          v-model="patprojectParams.status"
          placeholder="请输入答复状态"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <div class="tags" style="display: inline-block;width: 60%;">
          <el-select
            style="width: 100% !important;"
            clearable
            multiple
            v-model="patprojectParams.tag"
            placeholder="请选择标签"
            @change="changeTag"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item,index) in options"
              :key="index"
            ></el-option>
          </el-select>
        </div>
        <div style="width: 100%;margin: 10px 0;">
          <el-date-picker
            v-model="patprojectParams.issue_date"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="发文开始日期"
            end-placeholder="结束日期"
            @change="chooseTimeRange1"
          ></el-date-picker>
          <el-date-picker
            v-model="patprojectParams.app_date"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="申请开始日期"
            end-placeholder="结束日期"
            @change="chooseTimeRange1"
          ></el-date-picker>
          <el-date-picker
            v-model="patprojectParams.end_date"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="截止开始日期"
            end-placeholder="结束日期"
            @change="chooseTimeRange1"
          ></el-date-picker>
          <el-select
            clearable
            v-model="patprojectParams.surplus_days"
            placeholder="请选择剩余天数"
            @change="changePatentDays"
          >
            <el-option label="7天" value="7"></el-option>
            <el-option label="15天" value="15"></el-option>
            <el-option label="30天" value="30"></el-option>
            <el-option label="45天" value="45"></el-option>
            <el-option label="60天" value="60"></el-option>
          </el-select>
        </div>
        <el-input
          clearable
          v-model="patprojectParams.patent_name"
          placeholder="请输入专利名称"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-input
          clearable
          v-model="patprojectParams.app_code"
          placeholder="请输入专利号/申请号"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-input
          clearable
          v-model="patprojectParams.name"
          placeholder="请输入通知书名称"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-input
          clearable
          v-model="patprojectParams.applicant"
          placeholder="请输入申请人"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
        <el-button @click="exportAll()" class="import">全部导出</el-button>
      </div>
      <!-- <keep-alive> -->
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="patproject.data"
        style="width: 100%"
      >
        <el-table-column prop="patent_name" label="专利名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.patent_name ? scope.row.patent_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="tag" label="标签" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="Bottom Center 提示文字" placement="bottom">
              <template slot="content">
                <div class="tooltip-content">
                  <span style="color: #A86201;" v-if="scope.row.tag.includes('系统重复提交')">
                    系统重复提交
                    <br />
                  </span>
                  <span style="color: #8408FF;" v-if="scope.row.tag.includes('专利转让/变更')">
                    专利转让/变更
                    <br />
                  </span>
                  <span style="color: #1CC2FF;" v-if="scope.row.tag.includes('非正常申请重写')">
                    非正常申请重写
                    <br />
                  </span>
                  <span style="color: #03B262;" v-if="scope.row.tag.includes('非正常申请撤回')">
                    非正常申请撤回
                    <br />
                  </span>
                  <span style="color: #FFA01D;" v-if="scope.row.tag.includes('退费')">
                    退费
                    <br />
                  </span>
                  <span style="color: #FF2255;" v-if="scope.row.tag.includes('驳回/撤回重写')">
                    驳回/撤回重写
                    <br />
                  </span>
                  <span style="color: #ADB1B4;" v-if="scope.row.tag.includes('假专利')">
                    假专利
                    <br />
                  </span>
                  <span style="color: #F74691;" v-if="scope.row.tag.includes('代提交')">
                    代提交
                    <br />
                  </span>
                </div>
              </template>
              <span v-if="!scope.row.tag">-</span>
              <span style="color: #A86201;" v-if="scope.row.tag.includes('系统重复提交')">系统重复提交</span>
              <span style="color: #8408FF;" v-else-if="scope.row.tag.includes('专利转让/变更')">专利转让/变更</span>
              <span style="color: #1CC2FF;" v-else-if="scope.row.tag.includes('非正常申请重写')">非正常申请重写</span>
              <span style="color: #03B262;" v-else-if="scope.row.tag.includes('非正常申请撤回')">非正常申请撤回</span>
              <span style="color: #FFA01D;" v-else-if="scope.row.tag.includes('退费')">退费</span>
              <span style="color: #FF2255;" v-else-if="scope.row.tag.includes('驳回/撤回重写')">驳回/撤回重写</span>
              <span style="color: #ADB1B4;" v-else-if="scope.row.tag.includes('假专利')">假专利</span>
              <span style="color: #F74691;" v-else-if="scope.row.tag.includes('代提交')">代提交</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="app_code" label="专利号/申请号" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.app_code ? scope.row.app_code : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="patent_type" label="专利类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="!scope.row.patent_type">-</span>
            <span v-if="scope.row.patent_type == 1">发明专利</span>
            <span v-if="scope.row.patent_type == 2">实用新型</span>
            <span v-if="scope.row.patent_type == 3">外观设计</span>
            <span v-if="scope.row.patent_type == 4">植物新品种</span>
            <span v-if="scope.row.patent_type == 5">国家新药</span>
            <span v-if="scope.row.patent_type == 6">集成电路布图设计专有权</span>
            <span v-if="scope.row.patent_type == 7">国家级农作物品种</span>
            <span v-if="scope.row.patent_type == 8">国家一级中药保护品种</span>
            <span v-if="scope.row.patent_type == 9">其中：国防专利</span>
          </template>
        </el-table-column>
        <el-table-column prop="app_date" label="申请日期" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.app_date ? scope.row.app_date : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="applicant" label="申请人" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.applicant ? scope.row.applicant : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="通知书名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.name ? scope.row.name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="issue_date" label="发文日期" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.issue_date ? scope.row.issue_date : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="end_date" label="截止日期" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.end_date ? scope.row.end_date : "-"
            }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="app_date" label="申请日期" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.app_date ? scope.row.patent_status_internal : "-"
            }}
          </template>
        </el-table-column>-->

        <el-table-column prop="operation" label="操作" width="150px">
          <template slot="header">
            <div class="table_header">
              <span>操作</span>
            </div>
          </template>
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="patentEdit(scope.row.patent_id)">编辑</el-button>
            <el-button class="del" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- </keep-alive> -->
      <div class="block" v-if="patproject.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="patprojectParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="patprojectParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="patproject.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
    
    <script>
// import { mapGetters, mapState } from "vuex";
export default {
  data () {
    return {
      checkList: [],
      taglist: [],//标签编辑 标签选择框 model
      options: [
        {
          name: '系统重复提交',
          id: '1'
        },

        {
          name: '专利转让/变更',
          id: '2'
        },

        {
          name: '非正常申请重写',
          id: '3'
        },
        {
          name: '非正常申请撤回',
          id: '4'
        },
        {
          name: '退费',
          id: '5'
        },
        {
          name: '驳回/撤回重写',
          id: '6'
        },
        {
          name: '假专利',
          id: '7'
        },
        {
          name: '代提交',
          id: '8'
        }
      ],
      //   tagsData: [],//标签编辑表格
      //   dialogTags: false,//标签弹框默认false
      dialogTableVisible: false,
      loading: true,
      province: [],
      city: [],
      town: [],
      patprojectTotal: 0,
      patprojectParams: {
        tag: [],
        page: 1,
        limit: 10,
        keywords: "",
        patent_name: "",
        patent_type: "",
        status: "",
        company_name: "",
        order: "id desc",
      },
      privilige_list: {},
      patproject: [],
    };
  },
  activated () {
    // this.handleSearch();
    this.patprojectParams
  },
  created () {
    // let arr = localStorage.getItem('privilige')
    // this.privilige_list = JSON.parse(arr)
    if (this.$route.query.days) {
      this.$set(this.patprojectParams, 'status', this.$route.query.days)
      // this.patprojectParams.status = this.$route.query.days
      this.getList()
    } else {
      this.getList()
    }
  },
  methods: {
    patentEdit (patent_id) {
      this.$router.push({ path: '/patent/patent_edit', query: { id: patent_id, type: 'center' } })
    },
    getList () {
      this.axios.get('/api/project_patent/middle_list', { params: { ...this.patprojectParams } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    exportAll () {
      // this.$router.push('')
    },
    chooseTimeRange1 (t) {
      // console.log(t[0]);
      this.patprojectParams.end_date = t[1]
      this.loading = true
      this.axios.get('/api/project_patent/middle_list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    handleSizeChange (val) {
      this.patprojectParams.limit = val;
      this.loading = true
      this.axios.get('/api/project_patent/middle_list', { params: { ...this.patprojectParams } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    handleCurrentChange (val) {
      this.patprojectParams.page = val;
      this.loading = TextTrackCue
      this.axios.get('/api/project_patent/middle_list', { params: { ...this.patprojectParams, keywords: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    changePatentType () {
      this.loading = true
      this.axios.get('/api/project_patent/middle_list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    changePatentDays (x) {
      this.patprojectParams.surplus_days = x
      this.loading = true
      this.axios.get('/api/project_patent/middle_list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    changeTag (x) {
      this.loading = true
      this.axios.get('/api/project_patent/middle_list', { params: { ...this.patprojectParams, page: '', tag: x.join() } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    changeStatus () {
      this.loading = true
      this.axios.get('/api/project_patent/middle_list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    handleSearch () {
      // this.$store.dispatch("getpatproList", { ...this.patprojectParams, page: '', limit: '' });
      this.loading = true
      this.axios.get('/api/project_patent/middle_list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/project_patent/delete_middle", {
            params: {
              id: id,
            },
          }).then((a) => {
            if (a.code == 0) {
              this.$message.error(a.message)
            } else {
              this.$message({
                type: "success",
                message: a.message,
              });
              this.getList()
            }
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
    
<style scoped="scoped">
.flex-l {
  width: 80%;
}
.btn_head {
  width: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
}

.topUsers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.KCmodule .import {
  background: #edeef4;
  color: #909399;
  border: none;
}

::v-deep .el-tabs__content {
  top: 20px !important;
}

::v-deep .topUsers .el-input {
  width: 224px !important;
  margin: 10px 0;
  margin-right: 20px;
}

.el-date-editor--daterange.el-input__inner {
  width: 270px;
  margin-right: 20px;
}
div/deep/.tags .el-input {
  width: 100% !important;
}
</style>
    